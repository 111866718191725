<template>
  <div class="page-main-bg half-circle-bg">
    <v-container>
      <img :src="halfcircle2" alt="" loading="lazy" />
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_statistics.scss'

export default {
  name: 'statistics',
  components: {},
  data() {
    return {
      halfcircle2: require('@/assets/img/stat.png')
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer'])
  }
}
</script>
